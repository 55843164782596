import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';


@Pipe({ name: 'customDate' })
export class CustomDatePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) { }

  transform(date: string) {
    if (date) {
      return this.datePipe.transform(date, 'dd/MM/yyyy hh:mm a');
    }
    return '-';
  }
}
