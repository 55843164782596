import { Component } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from './shared/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private authService: AuthService,
    private db: AngularFireDatabase
  ) {

    this.authService.authState().subscribe(result => {

      // if (result) {

      //   const key = db.database.ref('J4q6x1cfPxTnuUzzrYBILUA34ri2/2020-08-15/8U2AaiyZck0DAEuS7PC6/').push({
      //     time: new Date().toISOString()
      //   }).key;

      //   console.log(key);

      // }

    });
  }

}
