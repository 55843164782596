// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { CountryCode } from 'libphonenumber-js';

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyDAT5m9cSlIk2xO52MizvKbnQn0f0WuCyY',
    authDomain: 'arrive-school-eb764.firebaseapp.com',
    databaseURL: 'https://arrive-school-eb764.firebaseio.com',
    projectId: 'arrive-school-eb764',
    storageBucket: 'arrive-school-eb764.appspot.com',
    messagingSenderId: '914004445947',
    appId: '1:914004445947:web:b1397bb6edb78a3f153e5e',
    measurementId: 'G-Y4V65VX5XG'
  },
  countryCode: 'UA' as CountryCode
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
