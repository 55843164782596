import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { User } from '../models/user';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private user$$: BehaviorSubject<User>;
  public user$: Observable<User>;
  
  constructor(
    private afa: AngularFireAuth,
    private afs: AngularFirestore,
    private userService: UserService
  ) {
    this.user$$ = new BehaviorSubject(undefined);
    this.user$ = this.user$$.asObservable();
  }

  authState(): Observable<User> {
    return this.afa.authState.pipe(
      tap(user => {
        
        if (user) {
          const userInfo: User = {
            displayName: user.displayName,
            email: user.email,
            phoneNumber: user.phoneNumber,
            photoURL: user.photoURL,
            providerId: user.providerId,
            uid: user.uid
          };
          this.userService.update(userInfo);
        }

      }),
      switchMap(fireUser => {
        if (fireUser) {
          return this.afs.doc(`users/${fireUser.phoneNumber}`).valueChanges();
        }
        return of(null);
      }),
      tap(user => {
        this.user$$.next(user);
      })
    );
  }

  currentUser() {
    return this.afa.currentUser;
  }

  loginWithUser(username: string, password: string) {
    return this.afa.signInWithEmailAndPassword(username, password);
  }
  logOut() {
    return this.afa.signOut();
  }

}
