import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { Dispatcher } from '../models/dispatcher';
import { BaseResponse, School } from '../models/school';
import { dashboardUser, User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private afs: AngularFirestore,
    private afa: AngularFireAuth,
    private aff: AngularFireFunctions
  ) { }

  update(user: User) {
    return this.afs.doc(`users/${user.phoneNumber}`).set(user, { merge: true });
  }

  getCurrentSchoolUser(schoolId: string, userId: string) {
    return this.afs.doc<any>(`schools/${schoolId}/Susers/${userId}`).get().pipe()
      .pipe(take(1))
      .toPromise()
      .then(snapshot => {
        return snapshot.data();
      });
  }

  getSchool(phoneNumber: string): Observable<School> {
    return this.afs.collectionGroup<Dispatcher>(`dispatchers`, ref => {
      return ref.where(`phoneNumber`, '==', phoneNumber).limit(1);
    }).valueChanges().pipe(
      map(results => {
        return results[0]?.id;
      }),
      switchMap(schoolId => {
        if (schoolId) {
          return this.afs.doc<School>(`schools/${schoolId}`).valueChanges();
        }
        return of(null);
      })
    );
  }

  getSchoolUsers$(schoolId: string) {
    return this.afs.collection<dashboardUser>(`schools/${schoolId}/Susers`, ref => {
      return ref;
    }).snapshotChanges()
      .pipe(
        map(snapshots => {
          return snapshots.map(snapshot => {
            return { id: snapshot.payload.doc.id, ...snapshot.payload.doc.data() };
          });
        })
      );
  }

  async deleteSchoolUser(user: dashboardUser) {
    user.action = "DELETE";
    const callable = this.aff.httpsCallable<dashboardUser, BaseResponse>('CreateSchoolUser');
    return await callable(user);
  }

  async addSchoolUser(user: dashboardUser) {
    user.action = "ADD";
    const callable = this.aff.httpsCallable<dashboardUser, BaseResponse>('CreateSchoolUser');
    return await callable(user);
  }

  async updateSchoolUser(user: dashboardUser) {
    user.action = "UPDATE";
    const callable = this.aff.httpsCallable<dashboardUser, BaseResponse>('CreateSchoolUser');
    return await callable(user);
  }
}
